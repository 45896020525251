import React, { useState, useEffect } from 'react'; 
import { Form } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import { Connector } from '../net/Connector';

/**
 * Page where users get directed to from their e-mail link to login.
 */
export const FinishLogin = (props: {
  onLoginSuccess: () => void,
  connector: Connector
}) => {
  const [searchParams] = useSearchParams();
  const [code, setCode] = useState('');
  let isLogin = searchParams.get('login');
  let state = searchParams.get('state');
  useEffect(() => {
    if (state) {
      props.connector.verifyLink(state, () => {
        props.onLoginSuccess();
      });
    }
  }, [state, props]);
  return state ? 
    <div><h3>Verifying login code...</h3></div> : 
    <Form>
      <legend>Finish Login</legend>
    <Form.Label>Check your e-mail and copy the code here:  </Form.Label>
    <Form.Control type="text" value={code} onChange={(e) => {
      setCode(e.currentTarget.value);
    }} />
    <button type="button" className="btn btn-primary mt-3" onClick={() => {
      props.connector.verifyLink(code, () => {
        props.onLoginSuccess();
      });
    }}>Submit</button>
    {isLogin ? <p className="mt-3">Tip! Check spam folders 
      and make sure you specified the e-mail that you used to sign up for the account.</p> : <></>}
  </Form>;
}