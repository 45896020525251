import React, { useState } from 'react'; 
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { Connector } from '../net/Connector';

/**
 * View that lets users log in or register.
 */
function LoginOrRegister(props: {
    isRegister: boolean,
    connector: Connector
}) {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [termsApproved, setTermsApproved] = useState(false);

  return <Form className="mt-3">
    <legend>{props.isRegister ? 'Sign up' : 'Log in'}</legend>
    {props.isRegister ? 
      <p>Or <Link to="../login">log in</Link> if you already have an account</p> :
      <p>Or <Link to="../register">sign up</Link> if you do not already have an account</p> }
    {props.isRegister ?
    <Form.Group>
      <Form.Label>Name</Form.Label>
      <Form.Control type="text" value={name} onChange={(e) => {setName(e.currentTarget.value)}}></Form.Control>
      </Form.Group>: 
    <></>}
    <Form.Group>
      <Form.Label className="col-2 col-form-label">E-mail</Form.Label>
      <Form.Control type="email" value={email} onChange={(e) => {setEmail(e.currentTarget.value)}}></Form.Control>
      </Form.Group>
    {props.isRegister ? <div className="d-flex flex-row mt-2"><Form.Check 
                type='checkbox'
                checked={termsApproved}
                name='termsApproved' 
                label=""
                onChange={(e) => {
                  setTermsApproved(e.currentTarget.checked);
                }}
                /><span>I have read and agree to the <a href="/terms.html">terms of service</a></span></div> :<></>}
    <button className="btn btn-primary mt-3" type="button" onClick={() => {
      props.isRegister ? 
        props.connector.register(name, email, () => { navigate('/finishLogin') }) :
        props.connector.requestMagicLinkLogin(email, () => { navigate('/finishLogin?login=true') });
    }} disabled={(props.isRegister && !termsApproved) || !email.includes('@')}>{props.isRegister ? 'Sign Up' : 'Log In'}</button>
  </Form>;
}

export default LoginOrRegister;