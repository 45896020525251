import React, { useEffect, useState, useRef } from 'react';
import { HashRouter, Link, Route, Routes, useNavigate } from 'react-router-dom';
import { ErrorAlert } from './ErrorAlert';
import { Session, SessionContext } from './net/Session';
import { Connector, Connection } from './net/Connector';
import LoginOrRegister from './user/LoginOrRegister';
import { FinishLogin } from './user/FinishLogin';
import Profile from './user/Profile';
import { Api } from './net/Api';
import { StatusButton } from './user/StatusButton';

const UnauthenticatedContent = (props: {
  connector: Connector,
  reinitializeSession: () => void
}) => {
  const navigate = useNavigate();
  return <div className="container">
    <Routes>
        <Route path="/finishLogin" element={<FinishLogin connector={props.connector} onLoginSuccess={() => {
          navigate('/');
          props.reinitializeSession();
        }}/>} />
        <Route path="/register" element={<LoginOrRegister connector={props.connector} isRegister={true}/>} />
        <Route path="/login" element={<LoginOrRegister connector={props.connector} isRegister={false}/>} />
        <Route path="*" element={<div>
          <p>This app was generated by <a href="http://suddenapp.com/">SuddenApp</a> with 
            customized <span className="text-primary">primary</span>,&nbsp;  
            <span className="text-secondary">secondary</span>,&nbsp;
            <span className="text-info">info</span>,&nbsp;
            <span className="text-success">success</span>, and&nbsp;
            <span className="text-danger">danger</span> colors. Edit <b>_variables.scss</b> to modify colors 
            or download a scss file for a full bootstrap theme in and import it into <b>custom.scss</b> to customize more parameters.
          </p>
          <p>Not logged in. Please <Link to="/login">log in</Link> or <Link to="/register">sign up for a new account</Link></p>
          </div>}/>
      </Routes>
  </div>;
}; 

const SidebarMenu = () => {
  return <div className="d-flex flex-column mx-3">
    <Link to="/">Home</Link>
    <Link to="profile">User</Link>
  </div>;
}

const AuthenticatedContent = () => {
  return <div className="d-flex flex-column flex-lg-row">
    <SidebarMenu />
    <div className="container">
      <Routes>
        <Route path="/" element={<h2>Main content</h2>} />
        <Route path="/profile" element={<Profile/>} />
      </Routes>
    </div>
  </div>;
}

// Replace with your own logo image in <img> tag
const LogoImagePlaceholder = () => {
  const logoCanvas = useRef<HTMLCanvasElement>(null);
  useEffect(() => {
    const ctx = logoCanvas.current!.getContext("2d")!;
    ctx.beginPath();
    ctx.font = 'bold 30px sans-serif';
    ctx.fillText('SuddenApp Example', 0, 30, 200);
    ctx.font = '10px sans-serif';
    ctx.fillText('Logo Placeholder', 0, 43);
  }, []);

  return <canvas
            ref={logoCanvas}
            width="200"
            height="45"
          >
            Your browser does not support the HTML canvas tag.
  </canvas>;
}

function App() {
  const [errorMessage, setErrorMessage] = useState<string>();
  const connector = new Connector(setErrorMessage);
  const [session, setSession] = useState<Session>();
  const connectionToSession = (connection: Connection) => {
    if (connection.isAuthenticated) {
      setSession(connection);
    } else {
      setSession(undefined);
    }
  };

  useEffect(() => {
    connector.initializeSession(connectionToSession);
    // setSession(MockSession); // Uncomment this line and comment out the line above to mock a session
  }, []);

  return (
    <HashRouter>
    <div className="d-flex flex-column">
      <header>
        <header className="d-flex flex-row justify-content-between">
          <Link to="/">
            <LogoImagePlaceholder />
          </Link>
          <StatusButton signedInUser={session?.user.name} signOut={() => {
            session?.api.signOut(() => {
              connector.initializeSession(connectionToSession);
            });
          }}/>
        </header>
      </header>
      <ErrorAlert message={errorMessage} dismissed={() => setErrorMessage(undefined)}/>
      {!session ? 
        <UnauthenticatedContent connector={connector} reinitializeSession={() => connector.initializeSession(connectionToSession)} /> :
        <SessionContext.Provider value={session}>
          <AuthenticatedContent />
        </SessionContext.Provider>}
    </div>
    </HashRouter>
  );
}

// Initialize the session hook with this MockSession to mock a logged-in user
const MockSession = {api: new Api(), user: { name: 'test', email: 'test@example.com' }}; // eslint-disable-line @typescript-eslint/no-unused-vars

export default App;
